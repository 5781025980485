export const TOOL_TYPE_DEFAULT_SETTINGS = {
  iFrame: {},
  URL: {
    baseUrl: '',
    mode: '_blank',
  },
  VNC: {
    askPassword: true,
  },
};
