import React, { useEffect, useState } from 'react';
import styles from '../css/index.module.scss';
import MyInput from '../../../../ui-kit/input';
import MyMultiSelect from '../../../../ui-kit/multi-select';
// import MyRadioSelect from '../../../../ui-kit/radio-select';

// import MyRadioSelect from '../../../../ui-kit/radio-select';
import useInputState from '../../../../helpers/hooks/useInputState';
import MyRadioSelect from '../../../../ui-kit/radio-select';
import { URL_MODE_DATA } from '../../../../helpers/local-data/radio-buttons-data';
import MyCheckbox from '../../../../components/checkbox';
import MyRadioButton from '../../../../ui-kit/radio-button';


function IFramePart({ settings, setSettings }) {




  const [topping, setTopping] = useState("Medium")

  const onOptionChange = e => {
    setTopping(e.target.value)
    setSettings((prev) => ({ ...prev, policy: e.target.value }));
  }


  const setKeepOpen = (val) => {
    setSettings((prev) => ({ ...prev, keepOpen: val }));
  };
  const setKeepOn = (val) => {
    setSettings((prev) => ({ ...prev, keepOpen: val }));
  };
  const setBaseUrl = (ev) => {
    setSettings((prev) => ({ ...prev, baseUrl: ev.target.value }));
  };
  const setWidth = (ev) => {
    setSettings((prev) => ({ ...prev, width: ev.target.value }));
  };
  const setHeigth = (ev) => {
    setSettings((prev) => ({ ...prev, height: ev.target.value }));
  };
  const setCounter = (ev) => {
    setSettings((prev) => ({ ...prev, counter: ev.target.value }));
  };
  const setCounterUrL = (ev) => {
    setSettings((prev) => ({ ...prev, counterURL: ev.target.value }));
  };
  const setTask = (ev) => {
    setSettings((prev) => ({ ...prev, selector: ev.target.value }));
  };
  const setLoginLink = (ev) => {
    setSettings((prev) => ({ ...prev, loginLink: ev.target.value }));
  };
  const setLogin = (ev) => {
    setSettings((prev) => ({ ...prev, login: ev.target.value }));
  };
  const setPass = (ev) => {
    setSettings((prev) => ({ ...prev, password: ev.target.value }));
  };


  return (
    <div className={styles.toolSettings}>
      <div className={styles.toolSettingsRow}>
        <p className={styles.toolSettingsTitle}>Keep open</p>
        <MyCheckbox
          className={styles.toolSettingsMain}
          value={settings.keepOpen}
          onChange={setKeepOpen}
          returnFinalVal
          rightComponent="Do not close the iFrame window if the user switches to another tab. Allows you to save state if the user navigates to an internal section in the iFrame or works with form filling"
        />

      </div>
      <div className={styles.toolSettingsRow}>
        <p className={styles.toolSettingsTitle}>Base URL</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.baseUrl || ''}
          onChange={setBaseUrl}
          placeholder="https://office.aiwess.com/jira?project=one&filter=open"
        />
      </div>
      <div className={styles.toolSettingsRow}>
        <p className={styles.toolSettingsTitle}>Width</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.width || ''}
          onChange={setWidth}
          placeholder="%"
        />

      </div>
      <div className={styles.toolSettingsRow}>

        <p className={styles.toolSettingsTitle}>Height</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.height || ''}
          onChange={setHeigth}
          placeholder="%"
        />
      </div>
      <div className={styles.toolSettingsRow}>

        <p className={styles.toolSettingsTitle}>Referrer Policy </p>
        <div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="no-referrer"
              checked={topping === "no-referrer"}
              onChange={onOptionChange}
            />
            <label>no-referrer</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="no-referrer-when-downgrade"
              checked={topping === "no-referrer-when-downgrade"}
              onChange={onOptionChange}
            />
            <label>no-referrer-when-downgrade</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="origin"
              checked={topping === "origin"}
              onChange={onOptionChange}
            />
            <label>origin</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="origin-when-cross-origin"
              checked={topping === "origin-when-cross-origin"}
              onChange={onOptionChange}
            />
            <label>origin-when-cross-origin</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="same-origin"
              checked={topping === "same-origin"}
              onChange={onOptionChange}
            />
            <label>same-origin</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="strict-origin"
              checked={topping === "strict-origin"}
              onChange={onOptionChange}
            />
            <label>strict-origin</label>
          </div>
          <div className={styles.toolSettingsRow}>
            <input
              className='radio-input'
              type="radio"
              name="topping"
              value="strict-origin-when-cross-origin"
              checked={topping === "strict-origin-when-cross-origin"}
              onChange={onOptionChange}
            />
            <label>strict-origin-when-cross-origin</label>
          </div>



        </div>



      </div>

      <div className={styles.authBoard}>
        {/* <p className={styles.toolSettingsTitle}>Auth</p> */}

        <div className={styles.authBoardRow}>
          <p className={styles.toolSettingsTitle}>Login page</p>
          <MyInput
            wrapperClassName={styles.toolSettingsMain}
            value={settings.loginLink || ''}
            onChange={setLoginLink}
            placeholder="https://office.aiwess.com/jira/"
          />
        </div>
        <div className={styles.authBoardRow}>
          <p className={styles.toolSettingsTitle}>Login</p>
          <MyInput
            wrapperClassName={styles.toolSettingsMain}
            value={settings.login || ''}
            onChange={setLogin}
            placeholder="login"
          />
        </div>
        <div className={styles.authBoardRow}>
          <p className={styles.toolSettingsTitle}>Password</p>
          <MyInput
            wrapperClassName={styles.toolSettingsMain}
            value={settings.password || ''}
            onChange={setPass}
            placeholder="pass"
          />
        </div>
      </div>

      <div className={styles.toolSettingsRow}>

        <p className={styles.toolSettingsTitle}>Counter URL</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.counterURL || ''}
          onChange={setCounterUrL}
          placeholder="https://office.aiwess.com/jira?project=one&filter=open"
        />

      </div>
      <div className={styles.toolSettingsRow}>

        <p className={styles.toolSettingsTitle}>jQuery selector</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.selector || ''}
          onChange={setTask}
          placeholder="#task__counter"
        />

      </div>
      <div className={styles.toolSettingsRow}>

        <p className={styles.toolSettingsTitle}>Counter regex</p>
        <MyInput
          wrapperClassName={styles.toolSettingsMain}
          value={settings.counter || ''}
          onChange={setCounter}
          placeholder="/{\d}/"
        />

      </div>

    </div>
  );
}

export default IFramePart;

/* {
    keepOpen: boolean,
    baseUrl: string,
} */
